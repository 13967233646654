var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bottom_block"}),_c('div',{staticClass:"m_bottom"},[_c('div',{staticClass:"m_bottom_body"},[_c('div',{staticClass:"m_bottom_body-list",on:{"click":_vm.clihome}},[(_vm.ub_index == 3)?_c('div',{staticClass:"m_icon-1_bo",class:_vm.$route.path == '/mhome' || _vm.$route.path == '/mhome.html' || _vm.$route.path == '/'
              ? 'm_icon-1-act_u'
              : ''}):(_vm.ub_index == 2)?_c('div',{staticClass:"m_icon-1_bo",class:_vm.$route.path == '/mhome' || _vm.$route.path == '/mhome.html' || _vm.$route.path == '/'
              ? 'm_icon-1-act_bo'
              : ''}):_c('div',{staticClass:"m_icon-1",class:_vm.$route.path == '/mhome' || _vm.$route.path == '/mhome.html' || _vm.$route.path == '/'
              ? 'm_icon-1-act'
              : ''}),_c('a',[_vm._v("首页")])]),_c('div',{staticClass:"m_bottom_body-list",on:{"click":function($event){return _vm.YS.routetogo({ path: '/mrecord', query: { isrec: false } })}}},[_c('div',{staticClass:"m_icon-2",class:_vm.$route.path == '/mrecord' || _vm.$route.path == '/mrecord.html' ? 'm_icon-2-act' : ''}),_c('a',[_vm._v("记录")])]),_c('div',{staticClass:"m_bottom_body-list",on:{"click":function($event){return _vm.YS.routetogo({ path: '/mmess' })}}},[_c('div',{staticClass:"m_icon-4",class:_vm.$route.path == '/mmess' || _vm.$route.path == '/mmess.html' ? 'm_icon-4-act' : ''}),_c('a',[_vm._v("帮助")])]),_c('div',{staticClass:"m_bottom_body-list",on:{"click":function($event){return _vm.cliuser()}}},[_c('div',{staticClass:"m_icon-5",class:_vm.$route.path == '/mcenter' || _vm.$route.path == '/mcenter.html' ? 'm_icon-5-act' : ''}),_c('a',[_vm._v("我的")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }