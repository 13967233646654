<template>
  <div>
    <input type="password" class="form-control" name="SecretOld" style="display: none" />
    <!-- 置顶top -->
    <transition name="mcenter_5">
      <div class="m_top" id="mTop" v-show="enter">
        <div class="m_top-nav">
          <img
            v-if="ub_index == 3"
            class="m_logo"
            @click="clilogo"
            src="../../assets/u/logo1.png"
            alt="logo图片"
          />
          <img
            v-else-if="ub_index == 2"
            class="m_logo"
            @click="clilogo"
            src="../../assets/bo/logo1.png"
            alt="logo图片"
          />
          <img
            v-else
            class="m_logo"
            @click="clilogo"
            src="../../assets/img/logo1.png"
            alt="logo图片"
          />
          <div class="m_search">
            <!-- <from  action="#"> -->
            <input
              type="search"
              @focus="clickSearchinput"
              v-model="topsearch"
              @keyup.enter="msearch(null)"
              :placeholder="inputNodes ? '搜索' + inputNodes : ''"
            />
            <img @click="msearch(null)" src="../../assets/img/msearch_2.png" alt="搜索图标" />
            <!-- </from> -->
          </div>
          <div class="m_mess_div">
            <div
              v-show="top.res == false && top.none == false && top.down == false"
              class="m_mess"
              @click="YS.routetogo({ path: '/mannoun' })"
            >
              <div class="bubble2" v-if="message_total">
                <span class="bubble-span2">{{ message_total }}</span>
              </div>
            </div>
            <!-- <div
              v-show="top.res == false && top.none == false && top.down == false"
              class="m_toold"
              @click="toold"
            >
              返回旧版
            </div> -->
            <div v-show="top.res || top.none || top.down" class="m_mess2" @click="cliquxiao">
              取消
            </div>
          </div>
        </div>
        <div class="topfix1">
          <div class="topfix2" @click="totopm" v-show="$store.state.mtopfloating.top">
            <img src="../../assets/img/mtotop.png" height="18" width="20" alt="返回顶部" />
          </div>
          <!-- <div class="topfix2" @click="toold">
            <img src="../../assets/img/mold2.png" alt="返回旧版" />
          </div> -->
          <!-- <div
            class="topfix2"
            @click="
              YS.routetogo({
                path: '/mdownapp',
              })
            "
          >
            <img src="../../assets/img/app_home.png" alt="APP下载" />
          </div> -->
          <!-- <div class="topfix3" @click="toold">
            <img src="../../assets/img/mold.png" height="18" width="20" />
            <span class="oldspan">旧版</span>
          </div> -->
        </div>
      </div>
    </transition>
    <div class="top_block" id="mtopBlock"></div>
    <!-- 没有您搜索的内容-搜索空数据时 -->
    <!-- <div class="msearch_none" v-show="top.none">
      <img src="../../assets/img/m_none2.0@2x.png" />
      <a>搜索内容为空,请重新输入.</a>
    </div> -->
    <!-- 搜索到的内容 -->
    <transition name="mcenter_4">
      <div class="msearch_res0" v-show="top.res">
        <!-- 标签标题 -->
        <div class="m_top-title" v-show="$store.state.mhomeshow.title">
          <div
            class="m_top-title-list"
            :class="{ 'm_top-title-list-act': titleindex == 0 }"
            @click="climtopTitle(null, 0)"
          >
            <a>全部</a>
            <div class="m_under"></div>
          </div>
          <template v-for="(item, index) in menutotalhome">
            <div
              class="m_top-title-list"
              :class="{ 'm_top-title-list-act': titleindex == index + 1 }"
              @click="climtopTitle(item.id, index + 1, item.symbol)"
              v-if="item.show"
              :key="index"
            >
              <a>{{ item.name }}</a>
              <div class="m_under"></div>
            </div>
          </template>
          <template>
            <div class="m_top-title-list"></div>
          </template>
        </div>
        <!-- 分类筛选 -->
        <div class="m_fil2" v-show="$store.state.mhomeshow.fil">
          <!-- 联动二级分类筛选 -->
          <div class="mfilter" v-if="menutotalhome[filindex - 1]">
            <a
              :class="{ 'mfilter-act': !menu_fu_act || menu_fu_act == 0 }"
              class="fil_box category_id_fil fil_fenlei"
              @click="changecategory_id(0, null)"
            >
              全部分类
            </a>
            <a
              v-for="(item, index) in menutotalhome[filindex - 1].list"
              :key="index"
              :class="{ 'mfilter-act': menu_fu_act - 1 == index }"
              class="fil_box category_id_fil fil_fenlei"
              @click="changecategory_id(index + 1, item.id)"
            >
              {{ item.name }}
            </a>
          </div>
          <div class="mfilter" v-show="!menutotalhome[filindex - 1]"></div>
          <div class="mfilter_body_list" v-for="(item, key) in ccc1" :key="key">
            <div
              :class="[key + '_fil', { 'mfilter-act': ccc2[key] == null || ccc2[key] < 0 }]"
              class="fil_box"
              @click="changerList(key, null)"
            >
              <a class="fil">全部{{ lang[key] }}</a>
            </div>
            <div
              :class="[key + '_fil', { 'mfilter-act': ccc2[key] == index }]"
              class="fil_box"
              v-for="(value, index) in item"
              :key="index"
              @click="changerList(key, index)"
            >
              <a class="fil">
                {{ value }}
              </a>
            </div>
          </div>
          <transition name="mcenter_11">
            <div class="mfilbox" v-show="video_enter">
              <div class="filbox_list1" v-for="(item, index) in video_list" :key="index">
                <!-- @click="clickSearchList(item.id)" -->
                <a
                  class="img_minh"
                  :title="item.title"
                  :href="
                    YS.returnURL(
                      {
                        path: '/mplay',
                        query: { video_id: item.id },
                      },
                      true,
                    )
                  "
                >
                  <img
                    class="filbox_img"
                    :alt="item.title"
                    :src="item.pic"
                    :onerror="$store.state.defaultimg"
                  />
                  <transition name="mhome_list_bottom">
                    <div class="mfilbox_bottom" v-show="item.picLoad">
                      <div class="mfilbox_bottom-line">
                        <div class="mfilbox_bottom-title">
                          <span v-if="isShowClassification(item.parent_category_id)">
                            {{ getClassification(item.parent_category_id, item.category_id) }}
                          </span>
                          <span v-else-if="item.multiple_fragment && item.state == '更新中'">
                            更新
                          </span>
                          <span v-else-if="item.multiple_fragment && item.state == '已完结'">
                            完结
                          </span>
                          <span v-else>{{ item.state }}</span>
                        </div>
                        <div class="mfilbox_bottom-num" v-show="item.multiple_fragment">
                          {{ item.last_fragment_symbol }}
                        </div>
                        <div class="mfilbox_bottom-fraction" v-show="item.score">
                          {{ item.score.toFixed(1) }}
                        </div>
                      </div>
                    </div>
                  </transition>
                </a>

                <a
                  class="filbox_span"
                  :href="
                    YS.returnURL(
                      {
                        path: '/mplay',
                        query: { video_id: item.id },
                      },
                      true,
                    )
                  "
                  :title="item.title"
                >
                  <span>{{ item.title }}</span>
                </a>
              </div>
            </div>
          </transition>
          <!-- 没有您搜索的内容-没有数据时 -->
          <!-- <div class="msearch_none4" v-show="!video_list && !filindex"> -->
          <div class="msearch_none4" v-show="!video_list && !loading">
            <img src="../../assets/img/m_none2.0@2x.png" alt="没有搜索到内容" />
            <a>没有您搜索的内容....</a>
          </div>
          <div class="msearch_none4" v-show="!video_list && loading">
            <img
              class="load0"
              src="../../assets/img/loading.png"
              height="100"
              width="100"
              alt="加载图片"
            />
          </div>
          <!-- 加载样式 -->
          <div class="bars-7" v-show="isloading && !istobottom"></div>
          <!-- 在一级选择为全部的选择下没有您搜索的内容-没有数据时 -->
          <a
            :href="YS.returnURL({ path: '/mcenter', query: { go: 'seekaPiece' } }, true)"
            class="msearch_none3"
            v-show="!video_list && !loading"
          >
            <a>我要求片</a>
          </a>

          <!-- 底线 -->
          <div class="istobottom" v-show="istobottom && !isloading && video_list">
            <img src="../../assets/img/dixian.png" alt="底部底线" />
            <div class="istobottom2">已经到最底部了</div>
          </div>
          <div class="bottom_blocks"></div>
        </div>
      </div>
    </transition>
    <!-- 搜索下拉框 -->
    <transition name="mcenter_4">
      <div class="m_searchlist" v-show="top.down">
        <div class="msearch-top-similar" v-show="topsearch && similarShow">
          <div class="m_similar_li" v-for="(item, index) in similarList" :key="index">
            <div class="m_similar_li-icon"></div>
            <a :href="YS.returnURL({ path: '/mplay', query: { video_id: item.id } }, true)">
              {{ item.title }}
            </a>
          </div>
        </div>
        <div class="m_searchlist-title" v-show="searchTop[0] && !topsearch">
          <a>历史搜索</a>
          <img @click="clearsearch" src="../../assets/img/m_del@2x.png" alt="清空图标" />
        </div>
        <div class="m_searchlist-list" v-show="!topsearch">
          <a
            class="m_searchlist-a"
            v-for="(item, index) in searchTop"
            :key="index"
            @click="msearch(item)"
          >
            {{ item }}
          </a>
        </div>
        <!-- 热门搜索 -->
        <div class="m_hotsearch" v-show="!topsearch || !similarShow">
          <a class="m_hotsearch-title">热门搜索</a>
          <div class="m_hotsearch-body">
            <div
              class="m_hotsearch-list"
              v-for="(item, index) in keywork_list"
              :key="index"
              @click="msearch(item)"
            >
              <a>{{ index >= 3 ? index + 1 + '.' : index + 1 }}</a>
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="show">
      <div class="mmess_top" v-show="mess_show">
        {{ messTop }}
      </div>
    </transition>
    <!-- WIN 没有绑定密码 -->
    <transition name="mcenter_8">
      <div class="mpayresult2" v-show="notBan" style="height: 310px !important">
        <!-- <div class="mpayresult2" v-show="true"> -->
        <div class="payrbody3">
          <a class="ubnot_a">您当前没有绑定手机或者邮箱</a>
          <a class="ubnot_a">丢失密码可能无法找回</a>
          <img class="marb_ubnot" src="../../assets/img/ubnot.png" alt="失败提示弹窗的主要图片" />
          <div class="m-nextNone">
            <input v-model="nextNone" type="checkbox" />
            <span ubnot_a @click="nextNone = !nextNone">下次不再提示</span>
          </div>
        </div>
        <div class="payafter_ban">
          <!-- <span>取消</span> -->
          <span class="payafter_ban-1" @click="laterBan">稍后绑定</span>
          <span
            class="payafter_ban-2"
            @click="YS.routetogo({ path: '/mcenter', query: { go: 'safe' } })"
          >
            马上绑定
          </span>
        </div>

        <img
          class="login-close"
          @click="openandclo('notBan', false)"
          src="../../assets/img/close.png"
          alt="关闭失败提示弹窗"
        />
      </div>
    </transition>
    <!-- WIN 隐藏版密码校验密码 -->
    <transition name="mcenter_8">
      <div class="mpayresult1 mpayresult_hidden" v-show="hiddenPass">
        <input type="password" class="form-control" style="display: none" />
        <!-- <div class="mpayresult1" v-show="true"> -->
        <div class="mpay_hidden_title" :class="{ hiddenColor: hiddenError }">
          {{ hiddenError ? '密码错误请重新输入' : '请输入午夜版密码' }}
        </div>
        <div class="payrbody_hidden-box">
          <input
            maxlength="20"
            v-model="hiddenValue"
            type="password"
            placeholder="初始密码1818，请前往安全设置中更改"
            @keydown.enter="checkHidden"
          />
        </div>
        <div class="payrbody_hidden" :class="{ cliloading: loadCli }" @click="checkHidden">
          <span v-show="!loadCli">确认</span>
          <div class="dots-6" v-show="loadCli"></div>
        </div>
        <img
          class="login-close"
          @click="openandclo('hiddenPass', false)"
          src="../../assets/img/close.png"
          alt="关闭视频报错弹窗"
        />
      </div>
    </transition>
    <!-- WIN 需要登录 -->
    <div class="mpayresult" v-show="mustlogin">
      <div class="payrbody7">
        <img src="../../assets/img/mustvip.png" alt="需要登录用户提示图片" />
        <span>非常抱歉，本功能需要登录用户才能体验哦！</span>
      </div>
      <div class="payrbody2">
        <div class="payrbodyflex2">
          <a class="payb_1" @click="openandclo('mustlogin', false)">返回</a>
          <a class="payb_2" @click="climustlogin">前往登录</a>
        </div>
      </div>
      <img
        class="login-close"
        @click="openandclo('mustlogin', false)"
        src="../../assets/img/close.png"
        alt="关闭需要用户登录弹窗"
      />
    </div>
    <!-- 遮罩层 -->
    <div id="coverm_top" @click="cliCoverm"></div>
  </div>
</template>
<script>
let that;

export default {
  //inject: ['routerRefresh'], //在子组件中注入在父组件中创建的属性
  name: 'Mtop',
  computed: {
    islogin: () => {
      return that.$store.state.islogin;
    },
  },
  watch: {
    topsearch() {
      // if (that.topsearch) that.getSimilar();
      //第一个判断条件筛选空格
      //第二个判断筛选有效字段后加空格，和避免上次重复搜索关键词
      if (
        that.topsearch.replace(/\s+/g, '') &&
        that.lastSeachKeyword != that.topsearch.replace(/\s+/g, '')
      ) {
        that.getSimilar();
        that.lastSeachKeyword = that.topsearch.replace(/\s+/g, '');
      }
    },
  },
  data() {
    return {
      ub_index: 1,
      nextNone: false,
      mustlogin: false,
      loadCli: false, //是否开启加载
      hiddenData: {},
      hiddenValue: '',
      hiddenError: false,
      // hiddenWuye_text: '请输入密码',
      //弹窗开关
      hiddenPass: false,
      inputNodes: '',
      notBan: false,
      phoneCountry: {},
      //这里定义哪些栏目在首页展示分类标签，跟据ID
      showClassification: [
        100, //电影
        105, //纪录片
        // 106, //粤台专区
        107, //儿童
      ],
      lastSeachKeyword: '',
      similarShow: false,
      similarList: [], //搜索词联想关键词
      mess_show: false,
      messTop: '', //消息提示
      JTstr: '', //简体容器
      message_total: null, //系统消息总数
      video_enter: true,
      video_l_code: true, //是否显示搜索无内容载体
      filgo: {}, //搜索关键词中转体
      enter: false,
      topsearch: '', //搜索框值
      top: {
        res: false,
        none: false,
        down: false,
      },
      video_list: null, //搜索列表
      keywork_list: [], //视频热门搜索词
      searchTop: [], //历史搜索存储
      filindex: null, //当前选中的分类下标
      menu_fu_act: null, //二级菜单的下标
      menutotalhome: [], //一二级菜单分类
      titleindex: 0,
      ccc1: {}, //带s的参数字段
      cccNight: null, //午夜版的
      cccDefault: null, //正常的
      loading: true, //打开加载
      isloading: false, //筛选页加载
      istobottom: false, //筛选页到底
      //记录当前筛选的选值,下标值
      ccc2: {
        languages: null,
        years: null,
        regions: null,
        states: null,
      },
      lang: {
        languages: '语言',
        years: '年份',
        regions: '地区',
        states: '状态',
        mosaic: '打码',
        tags: '喜好',
      },
      filact: {
        parent_category_id: null,
        category_id: null,
        language: null,
        year: null,
        region: null,
        state: null,
        keyword: '',
        paid: null,
        page: null,
        pagesize: null,
        mosaic: null,
        tags: null,
        sort_field: 'create_time', //排序字段
        sort_type: 'desc', //默认降序
      },
      fy: {
        pagenum: null,
        pagetotal: 20,
        pages: {
          page: 1,
          pagesize: 24,
        },
        mobanpage: [1, 2, 3, 4, 5, 6, 7, 8],
        pageList: [1, 2, 3, 4, 5, '...', 11, 12],
      },
      timewindowScroll_top: () => {},
    };
  },
  created() {
    that = this;
    this.ub_index = window.g.ub_index;
    // console.log(window.g);
    // debugger;
    //获取视频筛选词缓存
    // var cccstore = JSON.parse(localStorage.getItem('ccc1'));
    // if (cccstore) {
    //   if (cccstore.time > new Date().getTime()) {
    //     that.ccc1 = cccstore.data;
    //   }
    // }
    that.$store.commit('changelogin');
    if (!that.$store.state.islogin) {
      that.YS.routetogo({ path: '/mlogin' });
    }

    //获取菜单存储
    var menutotalstore = JSON.parse(localStorage.getItem('menutotal'));
    if (menutotalstore) {
      if (menutotalstore.time > new Date().getTime()) {
        // that.$store.commit('changeEvery', { key: 'menutotal', value: menutotalstore.data });
        that.menutotalhome = menutotalstore.data;
      }
    }
    this.timewindowScroll_top = this.YS.fnThrottle(this.windowScroll, 50);
  },
  destroyed() {
    window.removeEventListener('scroll', this.timewindowScroll_top);
  },
  mounted() {
    that.$store.commit('changelogin');
    that.getonetwo(); //api获取一级二级分类
    that.getvideofilter(); //获取视频筛选词
    that.enter = true;
    let stop = localStorage.getItem('topsearchword');
    that.searchTop = stop ? JSON.parse(stop) : [];
    if (that.$route.query.keyword) {
      that.msearch(that.$route.query.keyword);
    }
    // window.addEventListener('scroll', this.timewindowScroll_top);
    window.addEventListener('touchmove', this.timewindowScroll_top);
    that.$store.commit('changelogin');
    that.$store.commit('getisvipCode');
    // 当为登录状态时
    if (that.$store.state.islogin) {
      that.getmesslist(); // 获取通知消息列表
      if (!(that.$route.query.go && that.$route.query.go == 'safe')) {
        that.getInfo(); //获取用户信息，判断是否绑定邮箱/手机
      }
    }
  },
  methods: {
    laterBan() {
      that.openandclo('notBan', false);
      if (that.nextNone) {
        localStorage.setItem('nextNone', 1);
      }
    },
    cliCoverm() {
      that.openandclo('notBan', false);
    },
    //获取用户信息，判断是否绑定邮箱/手机
    async getInfo() {
      try {
        // that.vipOldtime = JSON.parse(localStorage.getItem('user')).vip_expired_time;
        let data = await that.api.user.api_info({});
        var tem_local = JSON.parse(localStorage.getItem('user'));
        tem_local.vip_expired_time = data.user.vip_expired_time;
        localStorage.setItem('user', JSON.stringify(tem_local));
        that.$store.commit('changelogin');
        that.$store.commit('getisvipCode');
        if (!data.user) return;
        // if (!data.user.email && !localStorage.getItem('nextNone')) {
        //   that.openandclo('notBan', true);
        // }
      } catch (error) {
        console.log(error.message);
      }
    },
    //开关窗口
    openandclo(type, boo) {
      that[type] = boo;
      var coverm_top = document.querySelector('#coverm_top');
      if (boo) {
        coverm_top.style.display = 'block';
      } else {
        coverm_top.style.display = 'none';
      }
    },
    //判断是否要返回分类名称
    isShowClassification(pid) {
      if (that.showClassification.indexOf(pid) >= 0) {
        return true;
      } else {
        return false;
      }
    },
    //获取分类的字段
    getClassification(pid, id) {
      if (!that.menutotalhome) return;
      let arrayIndex = that.menutotalhome.findIndex((item) => item.id == pid);
      if (arrayIndex >= 0) {
        let childIdex = that.menutotalhome[arrayIndex].list.findIndex((item) => item.id == id);
        if (childIdex < 0) return;
        return that.menutotalhome[arrayIndex].list[childIdex].name;
      } else {
        return;
      }
    },
    //用于图片加载完毕替换图片
    loadOverEasy(pic, i) {
      var newImg = new Image();
      newImg.src = pic;
      newImg.onload = () => {
        // return true;
        if (this.video_list && this.video_list[i]) {
          this.video_list[i].pic = newImg.src;
          this.$nextTick(() => {
            that.video_list[i].picLoad = true;
          });
        }
      };
    },
    //获取联想搜索词
    async getSimilar() {
      that.similarShow = false;
      try {
        let data = await that.api.user.api_videosuggest({
          keyword: that.topsearch.replace(/\s+/g, ''),
        });
        if (!data.suggest_list) return;
        that.similarList = data.suggest_list;
        if (data.suggest_list.length) {
          that.similarShow = true;
        }
      } catch (error) {
        that.similarShow = true;
        console.log(error.message);
      }
    },
    // 一级分类 / 点击大分类分栏
    climtopTitle(id, index, symbol) {
      that.filindex = index;
      if (symbol === 'night') {
        that.hiddenData.id = id;
        that.hiddenData.index = index;
        that.hiddenData.hidden = true;
        that.hiddenValue = '';
        that.filact.mosaic = '';
        that.filact.tag = '';
        if (!that.islogin) {
          that.openandclo('mustlogin', true);
          return;
        }

        that.openandclo('hiddenPass', true);
        return;
      }
      // 改变视窗高度，注释掉
      // index &&
      //   setTimeout(() => {
      //     var hei = that.getElementTop(document.getElementsByClassName('m_fil_title')[index - 1]);
      //     window.scrollTo({
      //       top: hei - 155,
      //       behavior: 'smooth',
      //     });
      //   }, 300);
      //当选中一级分类时
      // if (index > 0) {
      //   that.$store.commit('changeEvery', { key: 'mhomeshow', key2: 'swipe', value: false });
      //   that.$store.commit('changeEvery', { key: 'mhomeshow', key2: 'guanggao', value: false });
      //   that.$store.commit('changeEvery', { key: 'mhomeshow', key2: 'movie', value: false });
      // } else {
      //   that.$store.commit('changeEvery', { key: 'mhomeshow', key2: 'swipe', value: true });
      //   that.$store.commit('changeEvery', { key: 'mhomeshow', key2: 'guanggao', value: true });
      //   that.$store.commit('changeEvery', { key: 'mhomeshow', key2: 'movie', value: true });
      // }

      that.filact.category_id = null;
      that.menu_fu_act = 0;
      that.YS.toTop2();
      that.titleindex = index;
      that.filact.parent_category_id = id;
      that.getvideofilter();
      that.fy.pages.page = 1;
      that.getrou();
    },
    //二级分类点击事件
    changecategory_id(index, id) {
      //这里的index默认+1了，为了对应外面的全部按钮
      // if (index) {
      //   that.filact.category_id = id;
      // } else {
      //   that.filact.category_id = null;
      // }
      that.menu_fu_act = index;
      that.filact.category_id = id;
      that.fy.pages.page = 1;
      that.getrou();
    },
    // 小分类点击事件
    changerList(type, i) {
      //如果没传参，默认全选
      var typeAfter;
      switch (type) {
        case 'regions':
          typeAfter = 'region';
          break;
        case 'languages':
          typeAfter = 'language';
          break;
        case 'years':
          typeAfter = 'year';
          break;
        case 'states':
          typeAfter = 'state';
          break;
        case 'tags':
          typeAfter = 'tag';
          break;
        default:
          typeAfter = type;
          break;
      }
      if (!type) {
        // Object.keys(that.ccc2).forEach((e) => {
        //   // var obj = document.getElementsByClassName(e + '_fil');
        //   // that.YS.actlist(obj, that.ccc2[e] + 1, 'fil-act');
        // });
        if (type != undefined) that.filact[typeAfter] = null;
        // that.getVideolist();
        that.fy.pages.page = 1;
        that.getrou();
      } else {
        // var obj1 = document.getElementsByClassName(type + '_fil');
        that.ccc2[type] = i; //改变样式选择的下标
        that.filact[typeAfter] = that.ccc1[type][i]; //改变当前记录的选择内容
        // if (i == null) {
        //   that.YS.actlist(obj1, 0, 'fil-act');
        // } else {
        //   that.YS.actlist(obj1, that.ccc2[type] + 1, 'fil-act');
        // }
        that.fy.pages.page = 1;
        that.getrou();
      }
    },
    //更新跳转路由并获取视频列表
    getrou() {
      that.filact.page = that.fy.pages.page;
      that.filact.pagesize = that.fy.pages.pagesize;
      // that.YS.routetogo({
      //   path: '/fil',
      //   query: that.filact,
      // });
      that.getVideolist();
      // window.location.href = this.$route.fullPath;
    },
    //获取视频列表
    getVideolist() {
      if (!that.filact.keyword) {
        that.filact.sort_field = 'create_time';
      } else {
        that.filact.sort_field = 'score';
      }
      that.video_list = null; //清空列表
      that.video_l_code = false;
      that.video_enter = false;
      that.loading = true;
      that.api.user
        .api_videolist(that.filact)
        .then((data) => {
          that.loading = false;
          that.video_enter = true;
          that.video_list = data.video_list;
          that.video_total = data.video_total;
          that.fy.pagetotal = data.video_total;
          that.video_l_code = true;
          that.YS.jilu(that.fy);
          that.video_list &&
            that.video_list.forEach((e, i) => {
              that.loadOverEasy(e.pic, i);
              that.video_list[i].pic = '';
            });
        })
        .finally(() => {
          that.isloading = false;
        });
    },
    //需要登录  点击去登录
    climustlogin() {
      var coverm = document.querySelector('#coverm');
      coverm.style.display = 'none';
      var localNow = {};
      localNow.path = that.$route.path;
      localNow.query = that.$route.query;
      localNow.query.go = 'hidden';
      localStorage.setItem('mloginAfter', JSON.stringify(localNow));
      localStorage.setItem('mloginAfter_second', JSON.stringify(that.hiddenData));
      that.YS.routetogo({ path: '/mlogin' });
    },
    //api获取一级二级分类
    getonetwo() {
      if (!that.menutotalhome[0]) {
        that.api.user.api_categoryhome({}).then((data) => {
          data.category_list.forEach((e) => {
            if (e.pid == 0) {
              e.list = [];
              that.menutotalhome.push(e);
            } else {
              that.menutotalhome.forEach((a) => {
                if (a.id == e.pid) {
                  a.list.push(e);
                }
              });
            }
          });
          //存储至本地
          that.YS.savestore('menutotal', that.menutotalhome, 600);
        });
      }
      if (that.$parent.getRL) {
        that.$parent.getRL();
      }
    },
    // 获取视频筛选词
    getvideofilter() {
      let symbol = {};
      if (
        that.menutotalhome.findIndex(
          (item) => item.id == that.filact.parent_category_id && item.symbol === 'night',
        ) >= 0
      ) {
        symbol.category_symbol = 'night';
      }
      if (symbol.category_symbol && that.cccNight) {
        //是午夜版
        that.ccc1 = that.cccNight;
        return;
      } else if (!symbol.category_symbol && that.cccDefault) {
        that.ccc1 = that.cccDefault;
        return;
      }
      that.api.user.api_videofilter(symbol).then((data) => {
        if (symbol.category_symbol) {
          that.cccNight = data;
        } else {
          that.cccDefault = data;
        }
        that.ccc1 = data;
        // Object.keys(that.ccc2).forEach((e) => {
        //   that.ccc2[e] = that.ccc1[e].indexOf(that.filact[e]);
        // });
        //存储至本地
        // that.YS.savestore('ccc1', that.ccc1, 600);
        // this.$nextTick(() => {
        //   that.changerList(null);
        // });
      });
    },
    //校验隐藏的密码（午夜版）
    checkHidden() {
      if (that.loadCli) return false;
      that.hiddenError = false;
      that.loadCli = true;
      that.api.user
        .api_userverifyNightPassword({ password: that.hiddenValue })
        .then(() => {
          if (that.hiddenData) {
            that.climtopTitle(that.hiddenData.id, that.hiddenData.index);
            that.getvideofilter();
            that.openandclo('hiddenPass', false);
            that.mtop.messTop = '解锁成功';
            that.mtop.mess_show = true;
            setTimeout(() => {
              that.mtop.mess_show = false;
            }, 3000);
          }
        })
        .catch((error) => {
          console.log(error.message);
          that.hiddenError = true;
        })
        .finally(() => {
          that.loadCli = false;
        });
    },
    //点击logo事件
    clilogo() {
      if (that.$route.path == '/mhome') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      } else {
        that.YS.routetogo({ path: '/mhome' });
        //that.routerRefresh();
      }
      that.$store.commit('mhome', true);
    },
    //点击 搜索后里面的视频
    clickSearchList(id) {
      that.YS.routetogo({
        path: '/mplay',
        query: { video_id: id },
      });
    },
    // 点击搜索
    msearch(key, category_id) {
      that.fy.pages.page = 1;
      if (key) {
        that.filact.keyword = key;
        that.topsearch = key;
      } else {
        that.filact.keyword = that.topsearch;
      }

      //转换成简体
      that.JTstr = '';
      for (var i = 0; i < that.topsearch.length; i++) {
        if (that.YS.ftPYStr().indexOf(that.topsearch.charAt(i)) != -1)
          that.JTstr += that.YS.simpPYStr().charAt(
            that.YS.ftPYStr().indexOf(that.topsearch.charAt(i)),
          );
        else if (that.YS.qqPYStr().indexOf(that.topsearch.charAt(i)) != -1)
          that.JTstr += that.YS.simpPYStr().charAt(
            that.YS.qqPYStr().indexOf(that.topsearch.charAt(i)),
          );
        else that.JTstr += that.topsearch.charAt(i);
      }
      that.topsearch = that.JTstr;
      that.filact.keyword = that.JTstr;
      if (that.$route.query.keyword && that.$route.query.keyword.indexOf(key) < 0) {
        //如果是下拉的状态，那么记录上次选择搜索的内容

        // if (top.res || top.none || top.down) {
        //   that.YS.routetogo({
        //     path: '/mhome',
        //     query: that.filact,
        //   });

        that.YS.routetogo({
          path: '/mhome',
          query: { keyword: key },
        });
      }
      that.filgo = {};
      //是否下拉的状态，记录上次选择搜索的内容，加载搜索词
      if (that.top.res || that.top.none || that.top.down) {
        that.filgo = that.filact;
        that.filgo.page = that.fy.pages.page;
        that.filgo.pagesize = that.fy.pages.pagesize;
        that.video_list = null; //清空列表
      } else {
        that.filgo.keyword = that.topsearch;
        that.filgo.page = that.fy.pages.page;
        that.filgo.pagesize = that.fy.pages.pagesize;
      }
      // 如果没有搜索内容
      if (!this.topsearch) {
        Object.keys(this.top).forEach((e) => {
          top[e] = false;
        });
        //打开显示无内容
        this.top.none = true;
        //关闭加载
        that.loading = false;
      }
      // 如果有搜索内容
      else {
        that.video_enter = false;
        that.api.user
          .api_videolist(that.filgo)
          .then((data) => {
            that.loading = false;
            that.video_enter = true;
            that.video_list = data.video_list;
            that.fy.pagetotal = data.video_total;
            // if (!data.video_list) {
            //   that.istobottom = true;
            // }
            that.video_list.forEach((e, i) => {
              that.loadOverEasy(e.pic, i);
              that.video_list[i].pic = '';
            });
            that.YS.jilu(that.fy);
          })
          .finally(() => {
            that.isloading = false;
          });

        Object.keys(this.top).forEach((e) => {
          top[e] = false;
        });
        // //记录历史搜索
        // if (that.topsearch) {
        //   var a = that.searchTop;
        //   a.unshift(that.topsearch);
        //   that.searchTop = a.slice(0, 4);
        //   localStorage.setItem('topsearchword', JSON.stringify(that.searchTop.slice(0, 4)));
        // }
        // //记录历史搜索
        if (that.topsearch) {
          var a = that.searchTop;
          if (a.indexOf(that.topsearch) === -1) {
            a.unshift(that.topsearch);
            that.searchTop = a.slice(0, 4);
            localStorage.setItem('topsearchword', JSON.stringify(that.searchTop.slice(0, 4)));
          } else if (a.indexOf(that.topsearch) >= 0) {
            //当搜索的值存在搜索列表时
            that.searchTop = [];
            var num1 = a[a.indexOf(that.topsearch)]; //准备放到第一位
            a[a.indexOf(that.topsearch)] = null;
            a.forEach((e) => {
              !!e && that.searchTop.push(e);
            });
            that.searchTop.unshift(num1);
          }
        }

        // 打开显示搜索结果
        this.top.res = true;

        // var mtotal = document.getElementById('mtotal');
        // mtotal.setAttribute('style', ':nth-child(2){display:none}');
      }
      //如果有第二个参数,搜索ID,的话
      if (category_id) {
        this.top.none = false;
        that.video_enter = false;
        that.api.user
          .api_videolist({ category_id: category_id })
          .then((data) => {
            that.video_enter = true;
            that.video_list = data.video_list;
            that.fy.pagetotal = data.video_total;
            // if (!data.video_list) {
            //   that.istobottom = true;
            // }
            that.video_list.forEach((e, i) => {
              that.loadOverEasy(e.pic, i);
              that.video_list[i].pic = '';
            });
            that.YS.jilu(that.fy);
          })
          .finally(() => {
            that.isloading = false;
          });
        Object.keys(this.top).forEach((e) => {
          top[e] = false;
        });
        // 打开显示搜索结果
        this.top.res = true;
      }
      that.top.down = false;
      that.$store.commit('mhome', false);

      that.getvideofilter();
      // this.$store.state.mhome = false;
      // that.clickSearchinput();
      // if (!key) {
      //   alert('不能搜索为空');
      // }
    },
    //点击搜索框，获取视频热门搜索词
    clickSearchinput() {
      //适配个人中心页面，顶部背景色
      if (that.$parent.mcendg) {
        that.$parent.backgroundshort();
        that.$parent.mcendg.center = false;
      }
      //当存在dp，即为播放页时
      if (that.$parent.dp) {
        that.$parent.playhtml = false;
      }

      that.top.res = false;
      that.top.down = true;
      that.top.none = false;
      that.$store.commit('mhome', false);
      // that.$store.state.mhome = false;
      that.api.user.api_videokeyword({}).then((data) => {
        that.keywork_list = data.keywork_list;
      });
    },

    cliquxiao() {
      //适配个人中心页面，顶部背景色
      if (that.$parent.mcendg) {
        that.$parent.backgroundlong();
        that.$parent.mcendg.center = true;
      }
      //当存在dp，即为播放页时
      if (that.$parent.dp) {
        that.$parent.playhtml = true;
      }
      that.topsearch = '';
      // this.$store.state.mhome = true;
      that.$store.commit('mhome', true);
      Object.keys(this.top).forEach((e) => {
        this.top[e] = false;
      });
    },
    //清空历史搜索
    clearsearch() {
      that.searchTop = [];
      localStorage.setItem('topsearchword', []);
    },
    // 获取消息列表
    async getmesslist() {
      try {
        if (that.$store.state.message.message_total === null) {
          let data = await that.api.user.api_messagelist({ unread: 1 });
          that.message_total = data.message_total;
          that.$store.commit('changeEvery', {
            key: 'message',
            value: data.message_list,
            key2: 'message_list',
          });
          that.$store.commit('changeEvery', {
            key: 'message',
            value: data.message_total,
            key2: 'message_total',
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 滚动条距离页面顶部的距离
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let seeheight = document.documentElement.clientHeight || document.body.clientHeight;
      let filbox = document.getElementsByClassName('m_fil2')[0].offsetHeight;
      let m_home_block = document.getElementsByClassName('top_block')[0].offsetHeight;
      let filheight = filbox + m_home_block;
      //筛选页滑到下面时
      // console.log(filheight);
      // console.log(scrollTop + seeheight);
      if (
        that.video_list &&
        filbox &&
        filheight <= scrollTop + seeheight + 200 &&
        (that.fy.pages.page += 1)
      ) {
        //加载第N页，并添加到里面
        if (that.fy.pages.page < that.fy.pagenum) {
          that.isloading = true;
          that.pushvideo_list();
          that.istobottom = false;
        }
        if (that.fy.pages.page == that.fy.pagenum) {
          that.isloading = true;
          that.pushvideo_list();
          that.istobottom = true;
        }
      }
    },
    //二次加载，push添加视频数据
    pushvideo_list() {
      that.filact.page = that.fy.pages.page;
      that.filact.pagesize = that.fy.pages.pagesize;
      that.api.user
        .api_videolist(that.filact)
        .then((data) => {
          that.video_enter = true;
          data.video_list.forEach((e) => {
            that.video_list.push(e);
          });
          that.isloading = false;
          that.video_total = data.video_total;
          that.fy.pagetotal = data.video_total;
          that.video_list.forEach((e, i) => {
            try {
              if (that.video_list[i] && that.video_list[i].picLoad) {
                return;
              }
              that.loadOverEasy(e.pic, i);
              that.video_list[i].pic = '';
            } catch (error) {
              console.log(error);
            }
          });
          that.YS.jilu(that.fy);
        })
        .finally(() => {
          that.isloading = false;
        });
    },
    //返回顶部
    totopm() {
      that.YS.toTop2();
    },
    //返回旧版
    toold() {
      window.location.href = window.g.OLD_URL;
    },
  },
};
</script>
<style scoped lang="scss">
.m-nextNone {
  span {
    font-size: 15px;
    font-family: PingFang SC;
    // font-weight: bold;
    // line-height: 21px;
    color: #333333;
    opacity: 1;
  }
}
</style>
